<template>
  <list-cell :isSelected="isSelected" @onSelectClick="onSelectClick">
    <div class="sleep-cell">
      <cell-unit :style="{ width: '8%' }" :title="sleepItemModel.userName"></cell-unit>
      <cell-unit :style="{ width: '8%' }" :title="reportDate"></cell-unit>
      <cell-unit :style="{ width: '8%' }" :title="`${sleepItemModel.ahi}${sleepItemModel.ahiLevel}`"></cell-unit>
      <cell-unit :style="{ width: '16%' }" :color="sleepItemModel.monitorDuration.isLessThan4 ? '#FF0000' : undefined
        " :title="sleepItemModel.monitorDuration.text"></cell-unit>
      <cell-unit :style="{ width: '10%' }" :color="sleepItemModel.sleepTime.isLessThan3 ? '#FF0000' : undefined"
        :title="sleepItemModel.sleepTime.text"></cell-unit>
      <cell-unit :style="{ width: '8%' }" :color="!sleepItemModel.completion.isComplete ? '#FF0000' : undefined"
        :title="sleepItemModel.completion.text"></cell-unit>
      <cell-unit :style="{ width: '8%' }" :title="sleepItemModel.efficiency"></cell-unit>
      <cell-unit :style="{ width: '8%' }" :title="sleepItemModel.averageBloodOxygen"></cell-unit>
      <cell-unit :style="{ width: '8%' }" :title="sleepItemModel.minBloodOxygen"></cell-unit>
      <cell-unit :style="{ width: '8%' }" :title="sleepItemModel.oxygenIndex"></cell-unit>
      <div class="sleep-cell-button-container" :style="{ width: '15%' }">
        <div v-if="sleepItemModel.completion.isComplete" class="sleep-cell-button-container-content">
          <cell-unit color="#1890FF" :icon="require('@imgs/user-main-check.png')" title="查看" cursor="pointer"
            @onClick="onCheckDetailClick"></cell-unit>
          <cell-unit class="sleep-cell-button-container-content-right-button" color="#1890FF"
            :icon="require('@imgs/user-main-download.png')" title="下载" cursor="pointer"
            @onClick="onDownloadClick"></cell-unit>
        </div>
        <p v-else class="sleep-cell-button-container-invalid">无效报告</p>
      </div>
    </div>
  </list-cell>
</template>

<script>
import CellUnit from "@c/common/paging-list/cell-unit.vue";
import ListCell from "@c/common/paging-list/list-cell.vue";
import { DateTool } from "@js/date-tool.js";
import { SleepItemModel } from "@js/model/sleep-item-model.js";
import saveAs from "file-saver";

export default {
  components: {
    ListCell,
    CellUnit,
  },

  props: {
    model: Object,
    isSelected: Boolean,
  },

  computed: {
    sleepItemModel() {
      return new SleepItemModel(this.model);
    },

    reportDate() {
      const reportTimeStamp = this.sleepItemModel.reportTimeStamp;
      return placeholderText(
        DateTool.milliTimestampToStr(reportTimeStamp, "MM月DD日")
      );
    },
  },

  methods: {
    onSelectClick() {
      this.$emit("onSelectClick", this.model);
    },

    onCheckDetailClick() {
      const pdfUrl = this.model.pdfUrl;
      if (pdfUrl) {
        let routeData = this.$router.resolve({
          path: "/pdf-report",
          query: {
            pdfUrl
          },
        });
        window.open(routeData.href, "_blank");
      } else {
        this.$toast.showRed("报告地址为空")
      }
    },

    onDownloadClick() {
      const isValid = this.model.complete == "完整";
      const pdfURL = this.model.pdfUrl
      if (isValid && pdfURL) {
        const patientName = this.model.trueName;
        const createTime = DateTool.milliTimestampToStr(
          this.model.recordDay,
          "MM月DD日 HH：mm"
        );
        console.log(this.model.recordDay, createTime)
        const reportDownloadName = `${patientName}${createTime}`;
        saveAs(this.model.pdfUrl, reportDownloadName);
      } else {
        this.$toast.showRed("报告地址为空")
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.sleep-cell {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;

  &-button-container {
    display: flex;
    align-items: center;
    justify-content: center;

    &-content {
      display: flex;

      &-right-button {
        margin-left: 21px;
      }
    }

    &-invalid {
      color: #b3b3b3;
      font-size: 14px;
      font-weight: 300;
    }
  }
}
</style>