<template>
  <panel-alert title="睡眠监测报告" :isShowBottom="false" width="1520px" @onCloseClick="onCloseClick"
    @onConfirmClick="onConfirmClick">
    <template v-slot:sub-title>
      <p class="sleep-list-alert-sub-title">
        {{ reportDuration }}
      </p>
    </template>
    <template v-slot:content>
      <div class="sleep-list-alert">
        <page-list-container height="500px" :total="total" :totalPage="totalPage" :inputCurrentPage="currentPage"
          :pageSize="pageSize" @pagination="onPagination">
          <div>
            <list-title-bar></list-title-bar>
            <cell v-for="(item, index) of dataArray" :key="index" :model="item" :isSelected="selectedID === item.id"
              @onSelectClick="onCellSelectClick"></cell>
          </div>
        </page-list-container>
      </div>
    </template>
  </panel-alert>
</template>

<script>
import PanelAlert from "@c/common/alert/panel-alert/panel-alert.vue";
import PageListContainer from "@c/common/paging-list/paging-list.vue";
import ListTitleBar from "@c/main/patient/sleep-list/sleep-title-bar.vue";
import Cell from "@c/main/patient/sleep-list/sleep-cell.vue";
import { DateTool } from "@js/date-tool.js";
export default {
  components: {
    PanelAlert,
    PageListContainer,
    ListTitleBar,
    Cell,
  },

  props: {
    uid: Number,
    hospitalId: Number,
    start: String,
    end: String,
  },

  data: function () {
    return {
      //标题
      reportDuration: undefined,
      //列表
      dataArray: [],
      total: 0,
      totalPage: 0,
      currentPage: 1,
      pageSize: 6,
      //选中
      selectedID: undefined,
    };
  },

  created() {
    this.getReportDuration();
  },

  methods: {
    onCloseClick() {
      this.$emit("onCloseClick");
    },

    onConfirmClick() {
      this.$emit("onConfirmClick");
    },

    onPagination: function (currentPage) {
      this.currentPage = currentPage;
      this.getReportList();
    },

    onCellSelectClick(model) {
      this.selectedID = model.id;
    },

    // method
    async getReportDuration() {
      try {
        const params = {
          uid: this.uid,
          hospitalId: this.hospitalId,
          start: this.start,
          end: this.end
        };
        //getSleepReportDuration
        const data = await this.$api.getSleepReportDuration(params);
        const reportStartTime = DateTool.timeStrToStr(data.startTime, "YYYY-MM-DD");
        const reportEndTime = DateTool.timeStrToStr(data.endTime, "YYYY-MM-DD");
        this.reportDuration = `${reportStartTime}——${reportEndTime}`;
        //getReportList
        const startTimestamp = DateTool.timeStrToTimestamp(data.startTime)
        const endTimestamp = DateTool.timeStrToTimestamp(data.endTime)
        this.getReportList(startTimestamp, endTimestamp)
      } catch (error) {
        this.$toast.showRed(error)
      }
    },

    async getReportList(startTimestamp, endTimestamp) {
      try {
        const params = {
          pageNum: this.currentPage,
          pageSize: this.pageSize,
          uid: this.uid,
          startTime: startTimestamp,
          endTime: endTimestamp,
          hospitalId: this.hospitalId,
        };
        const data = await this.$api.getSleepReportPagingList(params);
        this.dataArray = data.list;
        this.total = data.total;
        this.totalPage = data.pages;
      } catch (error) {
        this.$toast.showRed(error);
      }
    },

  },
};
</script>

<style lang="scss" scoped>
.sleep-list-alert {
  position: relative;
  display: flex;
  flex-direction: column;

  &-sub-title {
    color: black;
    font-size: 14px;
    margin-top: 23px;
    margin-left: 15px;
  }

  &-top {
    display: flex;
    flex-direction: column;

    &-row {
      position: relative;
      margin-top: 50px;
      width: 100%;
      display: flex;
    }

    &-unit {
      position: absolute;

      &-slot {
        margin-left: 40px;
        font-weight: 300;
      }

      &-right {
        left: 230px;
      }
    }
  }

  &-bottom {
    margin-top: 60px;
    display: flex;
    flex-direction: column;

    &-cell {
      position: relative;
      font-size: 14px;
      color: black;
      font-weight: 300;
      width: 100%;
      margin-bottom: 30px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      &-name {
        position: absolute;
        font-weight: 400;
      }

      &-time {
        position: absolute;
        left: 60px;
      }

      &-ahi {
        position: absolute;
        left: 230px;
      }

      &-check {
        position: absolute;
        color: #2e6be6;
        left: 380px;
      }
    }
  }
}
</style>