<style lang="scss" scoped>
.list-title-bar-sleep {
  height: 54px;
  background-color: #fafafa;
  justify-content: space-around;
}
</style>

<template>
  <list-title-bar :titleArray="titleArray"></list-title-bar>
</template>

<script>
import ListTitleBar from "@c/common/paging-list/list-title-bar.vue";

export default {
  components: { ListTitleBar },

  computed: {
    titleArray() {
      return [
        { name: "姓名", percent: 8 },
        { name: "报告日期", percent: 8 },
        { name: "AHI/OAHI", percent: 8 },
        { name: "监测时段", percent: 16 },
        { name: "睡眠时长（TST）", percent: 10 },
        { name: "完整性", percent: 8 },
        { name: "睡眠效率", percent: 8 },
        { name: "平均血氧", percent: 8 },
        { name: "最低血氧", percent: 8 },
        { name: "氧减指数", percent: 8 },
        { name: "操作", percent: 15 },
      ];
    },
  },
};
</script>